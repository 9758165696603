import { PageProps } from "gatsby";
import React from "react";
import Layout from "../../components/parts/Layout";
import { NewsletterSubscriptionForm } from "../../components/parts/NewsletterSubscriptionForm";
import { snProps } from "../../js/utils";

const FollowByEmailPage = (props: PageProps) => {
  const title = "Follow the Clairnote Blog by Email";
  const description =
    "Get occasional emails announcing new posts on the Clairnote blog.";
  return (
    <Layout
      title={title}
      description={description}
      isBlog={true}
      {...snProps}
      {...props}
    >
      <article className="page">
        <h1 className="title is-3">{title}</h1>
        <p>
          Subscribe to the Clairnote Newsletter to get an email when new blog
          posts are published. The newsletter will occasionally contain
          additional news and updates. Typically there are between two and
          twelve messages per year.
        </p>
        <NewsletterSubscriptionForm sn={true} />
      </article>
    </Layout>
  );
};

export default FollowByEmailPage;
