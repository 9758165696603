import React from "react";

export const NewsletterSubscriptionForm = (props: { sn: boolean }) => {
  const { sn } = props;
  return (
    <form
      action="https://buttondown.email/api/emails/embed-subscribe/clairnote"
      method="post"
      target="popupwindow"
      onSubmit={() => {
        window.open("https://buttondown.email/clairnote", "popupwindow");
      }}
      className="embeddable-buttondown-form"
    >
      <div className="field">
        <label className="label" htmlFor="bd-email">
          Enter Your Email Address
        </label>
        <div className="control">
          <input
            className="input"
            placeholder="Email Address"
            type="email"
            name="email"
            id="bd-email"
          />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            className={`button ${sn ? "is-primary" : "is-info"}`}
            type="submit"
            value="Subscribe"
          />
        </div>
      </div>
      <p className="is-size-7">
        <a href="https://buttondown.email" target="_blank">
          Powered by Buttondown.
        </a>
      </p>
    </form>
  );
};
